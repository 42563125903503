.logoRrss {
    margin-left: 7px;
}

@media screen and (max-width: 1024px) {
    .logoFooter {
        width: 30%;
    }
    .logoRrss {
        padding-top: 30px;
    }
    .textFooter {
        padding-top: 30px;
    }
  }


@media screen and (max-width: 400px) {
    .logoFooter {
        width: 100%;
    }
  }